<template></template>

<script>
import { mapGetters } from 'vuex';
import { refreshToken, getTokenTemplate } from '../http/authentication';
import store from '@app/store';

export default {
  name: 'keepAlive',
  data() {
    return {
      interval: null
    };
  },
  created() {
    // Uncomment for debugging, pressinkeg r will trigger a token refresh
    // window.addEventListener('keydown', (ev) => {
    //   console.log(ev);
    //   if (ev.key === 'r') {
    //     this.refresh();
    //   }
    // });

    if (this.authenticated) {
      this.setRefreshInterval();
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'expires_in', 'user', 'userToken', 'refreshToken', 'templateId'])
  },
  methods: {
    refresh() {
      console.log('refreshToken keepAlive');
      if (this.userToken && this.refreshToken && this.user) {
        refreshToken(this.user, this.userToken, this.refreshToken).then((response) => {
          const { access_token, refresh_token, expires_in } = response.data;
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refresh_token');
          const templateId = window.localStorage.templateId || this.templateId;
          if (!templateId) {
            console.error('No template Id for refresh');
            return;
          }

          getTokenTemplate(access_token, templateId).then((response) => {
            store.dispatch('auth/setToken', { token: response.data.access_token, refresh_token, expires_in });
            if (window.$chatbotDispatch) {
              window.$chatbotDispatch({
                payload: {
                  name: 'access-token/refresh',
                  value: {
                    accessToken: response.data.access_token
                  }
                },
                type: 'WEB_CHAT/SEND_EVENT'
              });
            }
          });
        });
      }
    },
    setRefreshInterval() {
      // refresh mechanism 2 mins before expire date
      const tokenRefreshTime = Number(this.expires_in) * 1000 - 120000;
      this.interval = setInterval(this.refresh, tokenRefreshTime);
    }
  },
  watch: {
    authenticated(authenticated) {
      if (authenticated) {
        this.setRefreshInterval();
      } else {
        clearInterval(this.interval);
      }
    }
  }
};
</script>
